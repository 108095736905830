<template>
  <div v-if="artist">
    <section
      ref="hero"
      id="hero"
      class="hero has-cover"
      :class="{
        'width-contained': !fullHeightHero,
        'ar-stretched': aspectRatioFix
      }"
      :style="{
        backgroundImage: artist.profile.imgs.hero
          ? `url('${heroImage}')`
          : 'none',
        maxHeight: heroMaxHeight,
        mminHeight: heroMaxHeight
      }"
    >
      <div
        class="hero cover-mask"
        :style="{
          maxHeight: heroMaxHeight,
          minHeight: heroMaxHeight
        }"
      >
        <div class="hero-body">
          <div class="container has-text-centered is-hidden-touch">
            <h1
              v-if="nameOnHero"
              :style="{ fontSize: titleSize }"
              class="title is-1"
            >
              {{ nameOnHero }}
              <br />
            </h1>
            <h2
              v-if="claimOnHero"
              :style="{ fontSize: subtitleSize }"
              class="subtitle is-2"
            >
              {{ claimOnHero }}
            </h2>
          </div>
        </div>
        <div class="hero-foot">
          <a href="#profilenav" ref="triggerscroll">
            <div class="container has-text-centered has-text-white">
              <div class="tabs is-centered">
                <ul>
                  <li>getnext to {{ artist.nickname }}</li>
                </ul>
              </div>
              <arrow />
            </div>
          </a>
          <followBanner class="is-hidden-mobile" v-if="!artist.followed" />
        </div>
      </div>
    </section>

    <section v-if="claimOnHero" class="is-hidden-desktop section mt-25 mb-25">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1
              v-if="nameOnHero"
              :style="{ fontSize: titleSize }"
              class="
                title
                is-1
                has-text-weight-semibold has-text-primary
                is-spaced
              "
            >
              {{ nameOnHero }}
              <br />
            </h1>
            <p :style="{ fontSize: titleSize }" class="subtitle is-4">
              {{ claimOnHero }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section ref="profilesection" id="profilenav">
      <profile-nav />
    </section>

    <router-view :key="$route.fullPath" :params="params" />

    <portal v-bind:to="columnLayout">
      <filterPosts
        v-if="postStreamVisible"
        slot-scope="_"
        :layout="columnLayout"
      >
      </filterPosts>
    </portal>

    <cta
      v-if="!isAuthenticated || user.nickname !== artist.nickname"
      :artist="artist"
      :user="user"
      :isAuthenticated="isAuthenticated"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import profileNav from '~/components/profile-nav'
import arrow from '~/components/arrow'
import followBanner from '~/components/followBanner'
import cta from '~/components/ctaBig'
import filterPosts from '~/components/filter-posts'

const offsetOf = el => el.getBoundingClientRect().top

export default {
  layout: 'profile',
  name: 'profileComponent',

  components: {
    arrow,
    followBanner,
    cta,
    profileNav,
    filterPosts
  },

  provide() {
    return {
      moveEditor: null
    }
  },

  data: () => ({
    params: {},
    nameOnHero: false,
    claimOnHero: false,
    hero: null,
    windowWidth: 0,
    windowHeight: 0,
    largeHeroSizes: ['lg', 'lgr', 'xl', 'xxl'],
    defaultSocialImage: 'https://media.graphcms.com/mdDR80YqR3qpnA0cAtUC'
  }),

  computed: {
    activeSocials: function() {
      return Object.keys(this.artist.socialAccounts).filter(
        k => this.artist.socialAccounts[k].enabled
      )
    },
    fullHeightHero() {
      return (
        this.largeHeroSizes.indexOf(this.$mq) > -1
      )
    },
    heroMaxHeight() {
      if (this.hero && !this.fullHeightHero) {
        return '50vh'
      }
      return '100vh'
    },
    aspectRatioFix() {
      if (!this.hero) {
        return false
      }
      const heroAR = this.hero.width / this.hero.height
      const imgAR = 2 * this.windowWidth / this.windowHeight
      return imgAR < heroAR
    },
    titleSize() {
      if (this.fullHeightHero) {
        return '3.5rem'
      }
      if (['md', 'sm'].indexOf(this.$mq) > -1) {
        return '6vw'
      }
    },
    subtitleSize() {
      if (this.fullHeightHero) {
        return '2.5rem'
      }
      if (['md', 'sm'].indexOf(this.$mq) > -1) {
        return '4vw'
      }
    },
    heroImage() {
      if (typeof this.artist.profile.imgs.hero === 'string') {
        return this.artist.profile.imgs.hero
      }
      if (!this.artist.profile.imgs.hero.availableSizes) {
        return ''
      }

      const availableSizes = this.artist.profile.imgs.hero.availableSizes
      const image = availableSizes.find(size => {
        if (this.$mq === 'sm') {
          return size.name === 'hero-s'
        }
        if (this.$mq === 'md') {
          return size.name === 'hero-m'
        }
        return size.name === 'hero-max'
      })
      if (image) {
        this.hero = image
        return image.path
      }
      return ''
    },
    ogImage() {
      if (!this.artist) {
        return ''
      }
      if (typeof this.artist.profile.imgs.hero === 'string') {
        return this.artist.profile.imgs.hero
      }
      if (!this.artist.profile.imgs.hero.availableSizes) {
        return ''
      }
      const image = this.artist.profile.imgs.hero.availableSizes.find(size => size.name === 'hero-max')
      return image ? image.path : ''
    },

    ogDescription() {
      if (!this.artist || !this.artist.profile.description) {
        return 'getnext.to is a subscription-based platform where fans can get exclusive access to their stars, enabling a new level of relationship and interaction.'
      }

      const maxLength = 100
      let text = this.artist.profile.description.replace(/<(.|\n)*?>/g, '')
      if (text.length > maxLength) {
        text = `${text.substring(0, maxLength - 1)}...`
      }
      if (text.length === 0) {
        text = `Get closer to ${this.artist.nickname}! getnext.to is a subscription-based platform where fans can get exclusive access to their stars, enabling a new level of relationship and interaction.`
      }

      return text
    },

    columnLayout() {
      return this.isMobileView ? 'mobileFilterPosts' : 'desktopFilterPosts'
    },
    postStreamVisible() {
      if (this.$route && this.$route.name && this.$route.name.startsWith('id-posts')) {
        return true
      }
      if (this.$route && this.$route.name && this.$route.name.startsWith('id___')) {
        return true
      }
      return false
    },

    ...mapState(['user', 'artist', 'isAuthenticated'])
  },

  // expose data to head of website
  // @note: error function returns 'undefined'!
  head() {
    return {
      title: this.artist.nickname,
      meta: [
        {
          hid: `og:image`,
          property: 'og:image',
          content: this.ogImage ? `${process.env.API_BASE_URL_CLIENT}${this.ogImage}` : this.defaultSocialImage
        },
        {
          hid: 'og:description',
          property: 'description',
          content: this.ogDescription
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `getnext.to - ${this.artist.nickname}`
        }
      ]
    }
  },

  async fetch({ store, params, query, error, app }) {
    try {
      /**
       * todo: maybe cachable by use of current session?
       * app.context.app.$cookies.get('gtc_s')
       */
      const cookie = app.context.req ? app.context.req.headers.cookie : null

      const data = await app.apiGet(`/api/public/profile/${params.id}`,
        query,
        cookie
      )
      if (!data) {
        return error({ message: 'Artist not found', statusCode: 404 })
      }
      if (data) {
        store.commit('loadProfile', {
          artist: data.user,
          settings: data.settings
        })
      }
    } catch (e) {
      console.log('There was an error while requesting data fron the API', e)
      error({ message: 'Artist not found', statusCode: 404 })
    }
  },

  methods: {
    scrollHandler() {
      const mainnav = document.getElementById('mainnav')
      const area = this.$refs.profilesection
      if (area && mainnav) {
        const areaOffset = offsetOf(area)
        if (areaOffset <= 52) mainnav.classList.add('profile-is-sticky')
        if (areaOffset > 52) mainnav.classList.remove('profile-is-sticky')
        if (areaOffset <= 36) {
          mainnav.classList.add('light-shaded')
          mainnav.classList.remove('dark-shaded')
        }
        if (areaOffset > 36) {
          mainnav.classList.remove('light-shaded')
          if (window.scrollY > 5) {
            mainnav.classList.add('dark-shaded')
          } else {
            mainnav.classList.remove('dark-shaded')
          }
        }
      }
    },
    resizeHandler() {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async refreshAPI(options = {}) {
      if (document.visibilityState === 'visible') {
        console.log('in refreshAPI with options', options)
        try {
          const data = await this.apiGet(
            `/api/public/profile/${this.artist.urlAlias}`
          )
          if (!data) {
            return console.error('Error while fetching profile data from API.')
          }

          const profilePayload = {
            artist: data.user,
            settings: data.settings
          }

          if (options.visibilityChanged) {
            const storedPosts = await this.$localForage.posts.getItem(this.artist.urlAlias)
            if (storedPosts) {
              profilePayload.artist.posts = storedPosts.posts
              profilePayload.artist.stickyPosts = storedPosts.stickyPosts
              profilePayload.postFilter = storedPosts.postFilter
              profilePayload.profilePostsLoaded = storedPosts.profilePostsLoaded
            }
          }

          this.$store.commit('loadProfile', profilePayload)

          if (options.visibilityChanged) {
            this.$root.$emit('recoverPostFilter')
          }
        } catch (e) {
          console.error(
            'There was an error while refreshing data fron the API',
            e
          )
        }
      }
    },
    async changedVisibility() {
      return this.refreshAPI({ visibilityChanged: true })
    },

    resetTags() {
      this.$store.commit('updateTagFilter', [])
    }
  },

  mounted() {
    if (
      this.artist.profile.configuration &&
      !this.artist.profile.configuration.displayNameOnHero
    ) {
      this.nameOnHero = false
    } else {
      this.nameOnHero = this.artist.nickname
    }
    if (
      this.artist.profile.configuration &&
      !this.artist.profile.configuration.displayClaimOnHero
    ) {
      this.claimOnHero = false
    } else {
      this.claimOnHero = this.artist.profile.claim
    }

    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
    window.addEventListener('scroll', this.scrollHandler)
    window.addEventListener('resize', this.resizeHandler)

    if (navigator.userAgent.match(/iPad/i)) {
      this.$refs.hero.classList.add('ipad-cured')
    }

    if (document) {
      document.addEventListener('visibilitychange', this.changedVisibility)
    }

    this.$root.$on('refreshAPI', this.refreshAPI)
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('resize', this.resizeHandler)
    if (document) {
      document.removeEventListener('visibilitychange', this.changedVisibility)
    }
    this.$root.$off('refreshAPI', this.refreshAPI)
  },

  beforeRouteUpdate(to, from, next) {
    console.log(from, to)
    if (
      to.name.startsWith('id-posts___') || to.name.startsWith('id___')
    ) {
      this.resetTags()
    }
    next()
  }
}
</script>

<style lang="scss">
@import 'assets/sass/_variables';

.container {
  width: 100%;
}

section.has-cover {
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &.width-contained {
    background: no-repeat center center;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    -o-background-size: 100% auto;
    background-size: 100% auto;
  }

  &.ar-stretched {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }
}

.hero-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

div.cover-mask {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;

  .content,
  .subtitle,
  .title {
    color: inherit;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      color: inherit;
    }
  }

  .subtitle,
  .title {
    text-shadow: rgba(0, 0, 0, 0.2) 0 1px 0;
  }

  .title {
    font-weight: 300;
    // @media screen and (max-width: $desktop) {
    //   margin-bottom: 0;
    // }
  }
}

.media-content {
  margin-top: -6px;
}

.profileDescription blockquote:not(:last-child),
.profileDescription dl:not(:last-child),
.profileDescription ol:not(:last-child),
.profileDescription p:not(:last-child),
.profileDescription pre:not(:last-child),
.profileDescription table:not(:last-child),
.profileDescription ul:not(:last-child) {
  margin-bottom: 0;
}

#supportcta {
  height: 52px;
  border-radius: 0;
}

.profilebox {
  border-radius: 0;
}

.ipad-cured {
  background-size: auto 100vh !important;
  height: 100vh !important;
  background-position: top center !important;
  background-attachment: scroll;
}

</style>
