<template>
  <section class="hero is-primary">
    <div class="container has-text-centered">
      <a href="#" @click="follow" class="button is-outlined is-white is-large mt-20 mb-10">
        <strong>
          {{
            $t('follower.followHere', { artist: artist.nickname })
          }}
        </strong>
      </a>
    </div>
    <modal
      v-if="showFollowerModal"
      @close="closeFollowModal"
      :backdropClose="true"
      :defaultHeader="false"
      :defaultFooter="true"
    >
      <div slot="body">
        <div class="is-overlay">
          <section class="scale-hack">
            <div class="content">
              <div
                class="modal-card animated slideInDown login-modal pt-10 pl-10 pr-10 has-text-black"
              >
                <follower-login @logged="closeFollowModal"></follower-login>
              </div>
            </div>
          </section>
        </div>
      </div>
    </modal>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import followerLogin from '~/components/follower-login'
import modal from '~/components/modal'

export default {
  components: {
    followerLogin, modal
  },
  data: () => ({
    showFollowerModal: false
  }),

  mounted() {
    this.$root.$on('showGlobalFollowerModal', this.openFollowModal)
  },
  beforeDestroy() {
    this.$root.$off('showGlobalFollowerModal', this.openFollowModal)
  },

  methods: {
    ...mapActions(['setFollowArtist', 'refreshFollowerPosts']),
    async follow() {
      if (this.isAuthenticated) {
        const data = await this.apiPost(`/api/private/${this.artist.urlAlias}/follow/subscribe`, null, null)
        if (data && data.ok) {
          await this.setFollowArtist(true)
          await this.refreshFollowerPosts({ urlAlias: this.artist.urlAlias })
          this.sendFlashMessage()
        }
      } else {
        this.showFollowerModal = true
      }
    },
    openFollowModal() {
      this.showFollowerModal = true
    },
    closeFollowModal() {
      this.showFollowerModal = false
    },
    sendFlashMessage() {
      const imageId = this.artist.profile.imgs.avatar.name
      const flashConfig = {
        blockClass: 'flash-gn-profile-success'
      }
      if (this.avatarImageId) {
        flashConfig.icon = `/services/media/image/${this.artist._id}/${imageId}/128/0`
      }
      this.$root.$emit('flash', {
        type: 'toasts.followship.created',
        state: 'success',
        flashConfig,
        payload: {
          artist: this.artist.nickname
        }
      })
    }
  },
  computed: {
    avatarImageId() {
      if (
        !this.artist.profile ||
        typeof this.artist.profile.imgs.avatar === 'string'
      ) {
        return null
      }
      return this.artist.profile.imgs.avatar.name
    },
    ...mapState(['artist', 'isAuthenticated'])
  }
}
</script>
